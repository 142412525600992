<template>
  <v-overlay absolute>
    <v-progress-circular
      indeterminate
      :size="70"
      color="primary"
    />
  </v-overlay>
</template>

<script>
export default {
  name: 'LoaderFull'
}
</script>
